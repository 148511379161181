<template>
	<div class="purchase-invoice-table position-relative w-100 h-100 px-4 py-3 d-flex flex-column overflow-hidden">
		<table-summary title="Purchase Invoice" filter="invoiceNo" sort="receivedAt"
		               v-bind:order="-1"
		               v-bind:fields="fields"
		               v-bind:data-array="purchaseInvoices"
		               v-on:add-new="addInvoice"
		               v-on:row-clicked="navigate($event)"></table-summary>
	</div>
</template>

<script>
import TableSummary from "@/components/functional/table/TableSummary";
import { format } from "date-fns"

export default {
	name: "PurchaseInvoiceTable",
	
	components: {
		TableSummary,
	},
	
	data() {
		return {
			fields: [
				{ display: "Invoice No.", key: "invoiceNo", classBinding: ["f-3"] },
				{ display: "Supplier", key: "supplier", classBinding: ["f-5"] },
				{ display: "Status", key: "status", classBinding: [] },
				{ display: "Items", key: "items", classBinding: [] },
				{ display: "Currency", key: "currency", classBinding: [] },
				{ display: "Total", key: "totalAmount", classBinding: ["f-2"] },
				{ display: "Received At", key: "receivedAt", classBinding: [] },
			],
		}
	},
	
	computed: {
		purchaseInvoices() {
			return this.$store.state.contents.purchaseInvoices.map(invoice => {
				const obj = {...invoice};
				const supplier = this.$store.state.contents.suppliers.find(({ _id }) => _id === invoice.supplier);
				obj.supplier = supplier ? `${supplier.name} (${supplier.supplierNo})` : "N/A";
				obj.receivedAt = invoice.receivedAt ? format(new Date(invoice.receivedAt), "yyyy-MM-dd") : "N/A";
				obj.items = invoice.items.length; // type Number
				return obj;
			});
		}
	},
	
	methods: {
		async addInvoice() {
			await this.$router.push("/accounting/purchase-invoice/add");
		},
		
		async navigate(dataObj) {
			await this.$router.push(`/accounting/purchase-invoice/${dataObj._id}`);
		}
	}
}
</script>

<style lang="scss" scoped>

</style>